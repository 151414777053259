
"use client"
import 'regenerator-runtime/runtime'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/home'
import { verifySession } from '../store/slices/authSlice'
import AttendeeLayout from './attendee/AttendeeLayout'


export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const authState = useAppSelector((state) => state.auth)

  const LayoutComponent = AttendeeLayout

  //Verify Auth
  useEffect(() => {
    // console.log('VERIFY')
    dispatch(verifySession())
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    let storageUser = localStorage.getItem('user')
    if (storageUser && storageUser == '[object Object]') {
      localStorage.clear()
      router.push('/auth/login')
    } else {
      const user = storageUser ? JSON.parse(storageUser) : null
      if (!token && !router.pathname.includes('/register')) {
        router.push('/auth/login')
      }
      if (!!token && router.pathname.includes('/auth/login')) {
        router.push('/')
      }
      if (!!token && !router.pathname.includes('/auth/') && !user?.registered) {
        router.push('/payment')
      }
      if (!!token && router.pathname.includes('/payment')) {
        router.push('/')
      }
    }
  }, [authState])

  if (
    authState.loading ||
    (!authState.auth &&
      !router.pathname.includes('/auth/login') &&
      !router.pathname.includes('/register'))
  ) {
    return <div>Loading...</div>
  }

  return <LayoutComponent>{children}</LayoutComponent>
}
