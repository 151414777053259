import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '..'
import axios from '../../utils/axios'
import { toggleCartModal } from './modalSlice'
import { notifier } from './notificationSlice'
import Router from 'next/router'

// declaring the types for our state
export type MartState = {
  loading: boolean
  martLoading  : boolean,
  paymentLoading: boolean
  error: any | null
  cartData: any
}

const initialState: MartState = {
  error: null,
  loading: true,
  martLoading : true,
  paymentLoading: false,
  cartData: null,
}

export const martSlice = createSlice({
  name: 'mart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setMartLoading: (state, action: PayloadAction<boolean>) => {
      state.martLoading = action.payload
    },
    setPaymentLoading: (state, action: PayloadAction<boolean>) => {
      state.paymentLoading = action.payload
    },
    setMartData: (state, action: PayloadAction<any>) => {
      state.cartData = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading,setMartLoading, setPaymentLoading, setMartData, setError } =
  martSlice.actions

//API Calls
export const getMartData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setMartLoading(true))
      const response = await axios.get('/franchisee/product/mart')
      const data = response.data.response.data
      // console.log({ data })
      dispatch(setMartData(data))
      dispatch(setMartLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setMartLoading(false))
    }
  }

export const addToMart =
  ({ productId, qty }) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.post('/franchisee/product/mart', {
        productId,
        qty,
      })
      // console.log(response.data)
      const data = response.data.response.data
      dispatch(toggleCartModal(null))
      dispatch(setMartData(data))
      notifier.success(response.data.response.msg)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const updateMartData =
  ({ qty, productId }) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const cartId = store.getState().mart.cartData?.cartDetails?.mart?.[0]._id
      const response = await axios.patch(`/franchisee/update/mart/${cartId}`, {
        qty,
        productId,
      })
      const data = response.data.response.data
      dispatch(setMartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const removeFromMart =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      // const cartId = store.getState().mart.cartData?.cartDetails?.mart?.[0]._id
      const response = await axios.delete(`/empty-cart`, {
        // qty, productId, brand
      })
      const data = response.data.response.data
      dispatch(setMartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const addCustomerDetails = async (data) => {
  try {
    const cartId = store.getState().mart.cartData?.cartDetails?.mart?.[0]._id
    const res = await axios.patch(`/franchisee/update/mart/${cartId}`, data)
    // console.log(res.data)
  } catch (err) {
    console.error(err)
  }
}

export const payForMart =
  (customerDetails, payment_type) => async (dispatch: any) => {
    try {
      const cart = store.getState().mart.cartData?.cartDetails?.mart
      const profileData = store.getState().profile?.data
      if (!cart?.length) {
        return // console.log('No Cart')
      }
      await addCustomerDetails({ customerDetails })
      dispatch(setPaymentLoading(true))
      // @ts-ignore
      if (!window.Razorpay) {
        const script = document.createElement('script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'
        script.async = true
        document.body.appendChild(script)
      }
      // const params = { prescriptionId: pres._id, amount:cart[0].billing }
      // const response = await axios.post(`/createRazorpayPrescription`, params)
      // const data = response.data.response.data
      // console.log({ cart: cart[0].billingAmount * 100 })
      const options = {
        key: process.env.RAZORPAY_KEY,
        amount: (cart[0].billingAmount * 100).toFixed(2),
        name: 'EONBT',
        description: '',
        // order_id: cart[0]._id,
        async handler(response) {
          dispatch(setMartLoading(true))
          const res = await axios.post('/franchisee/mart/submit', {
            cartId: cart[0]._id,
            paymentId: response.razorpay_payment_id, // in COD send cash_payment
            order_id: response.razorpay_signature, // in COD send cash_payment
            wallet: false, // can be false in COD
            razorpay: true, // can be false in COD
            paymentByWallet: 0, // Not required in COD
            paymentByRazorpay: cart[0].billingAmount, // Not required in COD
            amount: cart[0].billingAmount,
            isCOD: false, // true in COD
          })
          // dispatch(removeFromMart())
          notifier.success(res.data.response.msg)
          dispatch(setPaymentLoading(false))
          dispatch(setMartLoading(false))
          Router.push('/success')
          // console.log(res.data)
        },
        prefill: {
          name: profileData?.firstName + profileData?.lastName,
          email: 'invoices@eonmed.in',
          contact: profileData.phone,
        },
        theme: {
          color: '#528ff0',
        },
      }
      //@ts-ignore
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      dispatch(setPaymentLoading(false))
      paymentObject.on('payment.failed', function (response) {
        window.location.reload()
        console.error({
          message: 'Payment Failed',
          description: `Payment Failed. Reason : ${response.error.reason}`,
        })
        dispatch(setMartLoading(false))
      })
    } catch (err) {
      dispatch(setPaymentLoading(false))
      dispatch(setMartLoading(false))
      console.error(err)
    }
  }

export const submitVitals = () => async (dispatch) => {
  try {
    // https://blue-firefly-115213.postman.co/workspace/Fresh-Workspace~17492b1a-d643-401e-8471-c50e3a77ae29/request/14586964-b814004a-2401-4a82-95f0-ea9da78003ff
  } catch (err) {}
}

// exporting the reducer here, as we need to add this to the store
export default martSlice.reducer
