import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import SvgIcon from '../shared/SvgIcon'
import { XCircleIcon } from '@heroicons/react/solid'
import SearchScreen from '../../components/SearchScreen'
import SearchProdScreen from '../../components/SearchProdScreen'

import { useRouter } from 'next/router'

export default function SearchModal({ search, open, setSearch, setOpen, onClose = () => { }, productList, patientList }) {
    const dispatch = useAppDispatch()
    const { pathname } = useRouter()
    const modelOpen = useAppSelector(state => state.home?.model)
    
    const closeModal = () => {
        setSearch("")
        setOpen(false)
    }




    return (

        <Transition appear show={modelOpen} as={Fragment} >
            <Dialog className="relative" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-2 mt-12 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full ml-28 h-full min-h-[86vh] single-article transform overflow-hidden rounded-2xl text-[#1B1E1F] p-2 text-left align-middle shadow-xl transition-all">
                                <span
                                    className="flex justify-end"
                                >
                                    <span className="sr-only">Close</span>
                                    <XCircleIcon onClick={closeModal} className="h-6 w-6 cursor-pointer" aria-hidden="true" />
                                </span>


                                <div>

                                    

                                    {!pathname.includes('/products') && !pathname.includes('/diagnostics') &&
                                        <div>
                                            {search && patientList.length ? (

                                                <SearchScreen
                                                    patientList={patientList}
                                                    onClose={() => {
                                                        setSearch('')
                                                    }}
                                                />
                                            )
                                                : <div>No Data Found in Patients</div>
                                            }

                                        </div>
                                    }
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
