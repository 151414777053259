import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type AuthState = {
  accessToken: string | null
  loading: boolean
  auth: boolean
  user: any | null
  error: any | null
}

const initialState: AuthState = {
  accessToken: null,
  loading: true,
  auth: false,
  user: null,
  error: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLogin: (state, action: PayloadAction<any>) => {
      // console.log(action.payload)
      localStorage.setItem('token', action.payload.accessToken)
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      state.loading = false
      state.auth = true
      state.user = action.payload.user
      state.accessToken = action.payload.accessToken
    },
    setLogout: (state) => {
      localStorage.removeItem('token')
      state.loading = false
      state.auth = false
      state.user = null
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setLogin, setLogout, setError } = authSlice.actions

//API Calls
export const login =
  (values: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      // const res = await fetch(`/public.txt`)
      // const publicKey = await res.text()
      // const encryptedData = crypto.publicEncrypt(
      //   { key: publicKey },
      //   Buffer.from(values.password)
      // )
      // const password = encryptedData.toString('base64')
      const response = await axios.post(
        `${process.env.API_URL}/franchisee/login`,
        {
          emailPhone: values.email.toLowerCase(),
          password: values.password,
          franchiseeType: 'SMART_ATTENDANT',
        }
      )
      const data = response.data.response.data
      // console.log(JSON.stringify(data))
      if (data.token) {
        dispatch(
          setLogin({
            accessToken: data.token,
            user: data.user,
          })
        )
      }
    } catch (e: any) {
      console.error(e)
      dispatch(setError(e.response?.data.error[0].msg))
      throw e
    }
  }

export const verifySession =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      const token = localStorage.getItem('token')
      dispatch(setLoading(true))
      if (token) {
        // console.log('Verified')
        const res = await axios.post('/session/verify', { token })
        const data = res.data.response?.data
        if (!data) throw new Error('Invalid Token')
        // console.log({ data, token })
        dispatch(
          setLogin({
            accessToken: token,
            user: data,
          })
        )
        localStorage.setItem('user', JSON.stringify(data))
      } else {
        dispatch(setLogout())
      }
    } catch (err) {
      // console.log(err)
      dispatch(setLogout())
      dispatch(setLoading(false))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default authSlice.reducer
