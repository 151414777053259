import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '..'
import axios from '../../utils/axios'
import { toggleCartModal } from './modalSlice'
import { notifier } from './notificationSlice'
import Router from 'next/router'

// declaring the types for our state
export type CartState = {
  loading: boolean
  productcartLoading : boolean
  paymentLoading: boolean
  error: any | null
  cartData: any
}

const initialState: CartState = {
  error: null,
  loading: true,
  productcartLoading : true,
  paymentLoading: false,
  cartData: null,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setProductCartLoading: (state, action: PayloadAction<boolean>) => {
      state.productcartLoading = action.payload
    },
    setPaymentLoading: (state, action: PayloadAction<boolean>) => {
      state.paymentLoading = action.payload
    },
    setCartData: (state, action: PayloadAction<any>) => {
      state.cartData = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading,setProductCartLoading, setPaymentLoading, setCartData, setError } =
  cartSlice.actions

//API Calls
export const getCartData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setProductCartLoading(true))
      const response = await axios.get('/franchisee/product/cart')
      const data = response.data.response.data
      // console.log({ data })
      dispatch(setCartData(data))
      dispatch(setProductCartLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setProductCartLoading(false))
    }
  }

export const addToCart =
  ({ productId, qty }) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.post('/franchisee/product/cart', {
        productId,
        qty,
      })
      // console.log(response.data)
      const data = response.data.response.data
      dispatch(toggleCartModal(null))
      dispatch(setCartData(data))
      notifier.success(response.data.response.msg)
      // dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const updateCartData =
  ({ qty, productId }) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const cartId = store.getState().cart.cartData?.cartDetails?.cart?.[0]._id
      const response = await axios.patch(`/franchisee/update/cart/${cartId}`, {
        qty,
        productId,
      })
      const data = response.data.response.data
      dispatch(setCartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const removeFromCart =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      // const cartId = store.getState().cart.cartData?.cartDetails?.cart?.[0]._id
      const response = await axios.delete(`/empty-cart`, {
        // qty, productId, brand
      })
      const data = response.data.response.data
      dispatch(setCartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

export const addCustomerDetails = async (data) => {
  try {
    const cartId = store.getState().cart.cartData?.cartDetails?.cart?.[0]._id
    const res = await axios.patch(`/franchisee/update/cart/${cartId}`, data)
    // console.log(res.data)
  } catch (err) {
    console.error(err)
  }
}

export const payForCart =
  (customerDetails, payment_type) => async (dispatch: any) => {
    try {
      const cart = store.getState().cart.cartData?.cartDetails?.cart
      if (!cart?.length) {
        return // console.log('No Cart Items')
      }
      await addCustomerDetails({ customerDetails })
      dispatch(setPaymentLoading(true))

      if (payment_type === 'cod') {
        const res = await axios.post('/franchisee/cart/submit', {
          cartId: cart[0]._id,
          paymentId: 'cash_payment', // in COD send cash_payment
          order_id: 'cash_payment', // in COD send cash_payment
          wallet: false, // can be false in COD
          razorpay: false, // can be false in COD
          // "paymentByWallet": 0,  // Not required in COD
          // "paymentByRazorpay": cart[0].billingAmount, // Not required in COD
          amount: cart[0].billingAmount,
          isCOD: true, // true in COD
          isPaid: true,
        })
        // dispatch(removeFromCart())
        notifier.success(res.data.response.msg)
        dispatch(setPaymentLoading(false))
        Router.push('/success')
        let orderData = res.data.response.data
        if (orderData?.testRecords?.length)
          Router.push(`/vitals/generate/${orderData.order._id}`)
        return
      }
      // @ts-ignore
      if (!window.Razorpay) {
        const script = document.createElement('script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'
        script.async = true
        document.body.appendChild(script)
      }
      // const params = { prescriptionId: pres._id, amount:cart[0].billing }
      // const response = await axios.post(`/createRazorpayPrescription`, params)
      // const data = response.data.response.data
      // console.log({ cart })
      const options = {
        key: process.env.RAZORPAY_KEY,
        amount: cart[0].billingAmount * 100,
        name: 'EONBT',
        description: '',
        // order_id: cart[0]._id,
        async handler(response) {
          const res = await axios.post('/franchisee/cart/submit', {
            cartId: cart[0]._id,
            paymentId: response.razorpay_payment_id, // in COD send cash_payment
            order_id: response.razorpay_signature, // in COD send cash_payment
            wallet: false, // can be false in COD
            razorpay: true, // can be false in COD
            paymentByWallet: 0, // Not required in COD
            paymentByRazorpay: cart[0].billingAmount, // Not required in COD
            amount: cart[0].billingAmount,
            isCOD: false, // true in COD
          })
          // dispatch(removeFromCart())
          notifier.success(res.data.response.msg)
          dispatch(setPaymentLoading(false))
          Router.push('/success')
          // console.log(res.data)
        },
        prefill: {
          name: customerDetails?.firstName + customerDetails?.lastName,
          email: 'invoices@eonmed.in',
          contact: customerDetails?.phone,
        },
        theme: {
          color: '#528ff0',
        },
      }
      //@ts-ignore
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      dispatch(setPaymentLoading(false))
      paymentObject.on('payment.failed', function (response) {
        window.location.reload()
        console.error({
          message: 'Payment Failed',
          description: `Payment Failed. Reason : ${response.error.reason}`,
        })
      })
    } catch (err) {
      dispatch(setPaymentLoading(false))
      console.error(err)
    }
  }

export const submitVitals = () => async (dispatch) => {
  try {
    // https://blue-firefly-115213.postman.co/workspace/Fresh-Workspace~17492b1a-d643-401e-8471-c50e3a77ae29/request/14586964-b814004a-2401-4a82-95f0-ea9da78003ff
  } catch (err) {}
}

// exporting the reducer here, as we need to add this to the store
export default cartSlice.reducer
