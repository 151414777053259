import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/home";
import { toggleComingSoon, toggledrugLisence } from "../../store/slices/modalSlice";
import { Button } from "../buttons/button";
import SvgIcon from "../shared/SvgIcon";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FormErrorMessage from "../shared/FormErrorMessage";
import axios from '../../utils/axios'

const DrugLicenseModel = () => {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.modal?.drugLisence.show)


    const profileData = useAppSelector((state) => state.profile?.data)





    const toggle = () => {
        dispatch(toggledrugLisence(false))
    }

    const fileChange = (e: any) => {
        formik.setFieldValue("images", e.target.files[0])
    }

    const formik = useFormik({
        initialValues: {
            license: "",
            images: ""
        },
        validationSchema: Yup.object().shape({
            license: Yup.string().required("Please enter License"),
            images: Yup.string().required("Please Upload Image")
        }),
        onSubmit: async (values) => {
            try {
                const formdata = new FormData()
                formdata.append('d_license', values.images)
                formdata.append('folder', 'drug_license')
                formdata.append('drugLicense', values.license)
                const response = await axios.patch(`franchisee/${profileData?.franchiseeId._id}`, formdata)
                dispatch(toggledrugLisence(false))
                formik.resetForm()
            } catch (e) {
                console.log("err", e)
            }
        }
    })



    return (
        <Transition appear show={show} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={toggle}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full single-article max-w-sm transform overflow-hidden rounded-2xl h-[370px] text-[#1B1E1F] bg-white p-6 text-center align-middle shadow-xl transition-all">


                                <div className='my-4 pb-2 flex flex-col space-y-4 items-center justify-center'>
                                    <h1 className="font-semibold">You must submit a valid <strong className="font-black">Drug License</strong> details to order medicines. Please submit the required details.
                                        <em>(If you have already submitted the details, please wait for the approval. It may take upto 24 hours to validate the details. Thank you !)</em></h1>
                                    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-3">
                                        <input
                                            type="text"
                                            name="license"
                                            value={formik.values.license}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            className="w-full rounded-md border-2 p-1"
                                            placeholder="License" />
                                        <FormErrorMessage
                                            touched={formik.touched.license}
                                            error={formik.errors.license}
                                        />


                                        <input name="images" type="file" onChange={fileChange} />
                                        <FormErrorMessage
                                            touched={formik.touched.images}
                                            error={formik.errors.images}
                                        />
                                        <button type="submit" className="inline-flex  items-center justify-center rounded-lg border border-transparent bg-blueColor  text-white">Submit</button>
                                    </form>
                                    {/* <SvgIcon src="/images/ourTeamProduct.svg" className='object-contain mx-auto' alt='ourTeamProduct' />
                                    <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Our Team is working hard to deliver you the best product. </p>
                                    <Button onClick={toggle} children="Go Back" className='p-3 px-5 w-fit mt-6' /> */}
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default DrugLicenseModel;