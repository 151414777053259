import '../styles/globals.css'
import '../styles/main.css'
import '../styles/swiper-bundle.min.css'
import { motion } from 'framer-motion'
import { store } from '../store'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import ErrorBoundary from '../functionality/ErrorBoundary'
import RootLayout from '../components/RootLayout'

config.autoAddCss = false

function MyApp({ Component, pageProps }: { Component: any; pageProps: any }) {
  return (
    <Provider store={store}>
      <RootLayout>
        <motion.div
          className="h-full"
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
          }}
        >
          <ErrorBoundary>
            <Component {...pageProps}></Component>
          </ErrorBoundary>
        </motion.div>
      </RootLayout>
    </Provider>
  )
}

export default MyApp
