import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';
class ErrorBoundary extends Component<{ children: JSX.Element }, { hasError: boolean, error: any }> {

   constructor(props: { children: JSX.Element; } | Readonly<{ children: JSX.Element; }>) {

      super(props)

      this.state = {
         hasError: false,
         error: null
      };

   }
   static getDerivedStateFromError(error: any) {
      Sentry.captureException(error)
      return { hasError: true, error };
   }

   render() {
      if (this.state.hasError == true) {
         return <div>
            <h1>Something went wrong</h1>
            {this.state.error && <p>{this.state.error}</p>}
         </div>
      } else {
         return this.props.children;
      }
   }
}

export default ErrorBoundary;