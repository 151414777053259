import { useEffect, useState } from 'react'
// import Sidebar from './Sidebar'
import Header from './Header'
import { useRouter } from 'next/router'
// import Slider from './Slider'
// import Modals from './modals/Modals'
import NotificationContainer from '../shared/NotificationContainer'
import axios from '../../utils/axios'
import SearchScreen from '../../components/SearchScreen'
import SearchProdScreen from '../SearchProdScreen'
import SocketService from '../../services/socketService'
import Sidebar from './Sidebar'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import PDFModal from '../modals/PDFModal'
import AttachmentModal from '../modals/AttachmentModal'
import SearchModal from './SearchModal'
import ModalContainer from '../shared/modal'
import OrderModal from '../modals/OrderModal'
import {
  togglePdfModal,
  toggleAttachmentModal,
  togglediagnosticUpload,
} from '../../store/slices/modalSlice'
import ComingSoonModal from '../modals/ComingSoonModal'
import CartModal from '../modals/CartModal'
import DiagnosticUpload from '../modals/DiagnosticUpload'
import { setModel } from '../../store/slices/homeSlice'
import DiagnosticSearchModel from './DiagnosticSearchModel'
import { getProductList } from '../../store/slices/productSlice'
import DrugLicenseModel from '../modals/DrugLicenseModel'

export default function AttendeeLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const dispatch = useAppDispatch()
  const { pathname } = useRouter()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [patientList, setPatientList] = useState([])
  const [productList, setProductList] = useState([])
  const [diagnosticList, setDiagnosticList] = useState([])
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(true)
  const prescription = useAppSelector(
    (state) => state.prescription.selectedPrescription
  )
  const pdfModal = useAppSelector((state) => state.modal.pdfModal)
  const attachModal = useAppSelector((state) => state.modal.attachmentModal)
  const diagnosticUploadModal = useAppSelector(
    (state) => state.modal.diagnosticUpload
  )
  const authData = useAppSelector((state) => state.auth?.user)
  const profileData = useAppSelector((state) => state.profile?.data)
  const pData = useAppSelector(
    (state) => state.product?.data?.data
  )

  useEffect(() => {
    var socket = SocketService.socket
    if (!socket) socket = SocketService.init()
    socket.on('patient_joined_queue', (data: any) => {
      // console.log('patient_joined_queue', data)
      new Notification(
        `${data.firstName} ${data.lastName || ''} is waiting in Queue !`
      )
    })
  }, [])

  useEffect(() => {
    if (
      !search ||
      pathname.includes('/products') || pathname.includes('/mart') ||
      pathname.includes('/diagnostics')
    )
      return
    axios
      .get('doctor/search/patient?name=' + search)
      .then((response) => {
        const data = response.data.response.data
        setPatientList(data)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [search])

  useEffect(() => {
    if (
      !search ||
      pathname.includes('/diagnostics')
    )
      return
    if (pathname.includes('/products')) {
      dispatch(getProductList(1, search, "product"))
      // axios
      // .get('product?name=' + search)
      // .then((response) => {
      //   const data = response.data.response.data
      //   // console.log("data", data)
      //   setProductList(data)
      // })
      // .catch((err) => {
      //   // console.log(err)
      // })
    } else if (pathname.includes('/mart')) {
      dispatch(getProductList(1, search, "mart"))
      // axios
      // .get('product?mart=true&name=' + search)
      // .then((response) => {
      //   const data = response.data.response.data
      //   // console.log("data", data)
      //   setProductList(data)
      // })
      // .catch((err) => {
      //   // console.log(err)
      // })
    } else {
      return
    }

  }, [search])



  useEffect(() => {
    if (!search || !pathname.includes('/diagnostics')) return
    axios
      .patch('testsByFilter', {
        city: profileData?.franchiseeId?.city,
        category: '',
        testName: search,
      })
      .then((response) => {
        const data = response.data.response.data
        // console.log("diagnostic", data)
        setDiagnosticList(data)
      })
      .catch((err) => {
        // console.log(err)
      })
  }, [search])

  const onClose = () => {
    setSearch('')
  }

  useEffect(() => {
    if (search) {
      dispatch(setModel(true))
      //setOpen(true)
    }
  }, [search])

  useEffect(() => {
    setSearch('')
  }, [pathname])



  return (
    <>
      <div className="flex h-screen flex-col">
        {!pathname.includes('/auth/') && !pathname.includes('/register') &&
          // !pathname.includes("/camp") &&
          (!pathname.match('/consultation') ||
            prescription?.isVideoConsultationDone) && (
            <Header
              search={search}
              setSearch={setSearch}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          )}

        {/* Hide Header */}
        <div className="flex flex-1 flex-row overflow-hidden">
          {/* Hide sidebar */}
          {!pathname.includes('/auth/') && !pathname.includes('/register') &&
            // !pathname.includes("/camp") &&
            (!pathname.match('/consultation') ||
              prescription?.isVideoConsultationDone) && (
              <Sidebar
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            )}

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                <>
                  {search && (pathname.includes('/products') || pathname.includes('/mart')) ? (
                    <SearchProdScreen
                      type={pathname.includes('/products') ? "product" : "mart"}
                      onClose={() => {
                        setSearch('')
                      }}
                    />
                  ) : (
                    children
                  )}
                  {search &&
                    !pathname.includes('/products') && !pathname.includes('/mart') &&
                    !pathname.includes('/diagnostics') && (
                      <SearchModal
                        {...{ open, setOpen }}
                        setSearch={setSearch}
                        search={search}
                        onClose={onClose}
                        productList={productList}
                        patientList={patientList}
                      />
                    )}

                  {search &&
                    !pathname.includes('/products') && !pathname.includes('/mart') &&
                    pathname.includes('/diagnostics') && (
                      <DiagnosticSearchModel
                        {...{ open, setOpen }}
                        setSearch={setSearch}
                        search={search}
                        onClose={onClose}
                        diagnosticList={diagnosticList}
                      />
                    )}
                </>
              </section>
            </main>
          </div>
        </div>
        {/* <Slider />*/}
        {pdfModal.show && (
          <ModalContainer
            open={pdfModal.show}
            onClose={() => dispatch(togglePdfModal(null))}
          >
            <PDFModal />
          </ModalContainer>
        )}

        {attachModal.show && (
          <ModalContainer
            open={attachModal.show}
            onClose={() => dispatch(toggleAttachmentModal(null))}
          >
            <AttachmentModal />
          </ModalContainer>
        )}

        {diagnosticUploadModal.show && (
          <ModalContainer
            open={diagnosticUploadModal.show}
            onClose={() => dispatch(togglediagnosticUpload(null))}
          >
            <DiagnosticUpload />
          </ModalContainer>
        )}

        <ComingSoonModal />
        <CartModal />
        <OrderModal />
        <NotificationContainer />
        <DrugLicenseModel />
      </div>
    </>
  )
}
