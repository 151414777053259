import React from 'react'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
const Toggle = ({
    enableChild,
    disableChild,
    className,
    checked,
    name,
    onChange = () => { },
}: {
    enableChild?: React.ReactNode
    disableChild?: React.ReactNode
    checked?: boolean
    className?: string 
    onChange?: any
    name?:string
}) => {
    const [enabled, setEnabled] = useState(false)
    

    return (
        <div className="flex">
            <Switch
                name={name}
                checked
                onChange={onChange}
                className={`${checked ? 'bg-blueColor' : 'bg-[#D3D3D3]'}
          relative inline-flex h-[30px] w-[82px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                 <span className={`${checked ? 'left-0 pl-2' : 'right-0'} absolute flex justify-center text-xs items-center text-white py-1`}> {checked ? <span>{enableChild} </span>: <span>{disableChild}</span>}</span>
                <span
                    aria-hidden="true"
                    className={`${checked ? 'right-0' : 'left-0'}
            pointer-events-none inline-block h-[26px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out absolute`}
                />
            </Switch>
        </div>
    )
}

export default Toggle
