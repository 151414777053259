import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'
import SearchCard from './attendee/SearchCard'
import Avatar from './shared/Avatar'
import FeatureProductCard from './products/FeatureProductCard'
import { useAppDispatch, useAppSelector } from '../hooks/home'
import {
  getProductList,
  setToggleDiagnosticStatus,
  setToggleMedicineStatus,
} from '../store/slices/productSlice'
import ReactPagination from '../Comp/ReactPagination'
import Toggle from '../Comp/Toggle'
import Loader from '../Comp/Loader'
import { toggledrugLisence } from '../store/slices/modalSlice'

const SearchProdScreen = ({ onClose, type }: { onClose: any; type: any }) => {
  const router = useRouter()

  const dispatch = useAppDispatch()

  const [pageNumbers, setPageNumbers] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const pData = useAppSelector((state) => state.product?.data?.data)

  const loading = useAppSelector((state) => state.product?.loading)

  const createdTotalPage = useAppSelector((state) => state.product?.data?.total)
  const createdCurrentPage = useAppSelector(
    (state) => state.product?.data?.current
  )

  const isMedicine = useAppSelector(
    (state) => state.product?.toggleStatus.medicine
  )
  const isDiagnostic = useAppSelector(
    (state) => state.product?.toggleStatus?.diagnostic
  )
  const user = JSON.parse(localStorage.getItem('user') || '')
  let DrugLicenseValid = user?.isDrugLicenseValid

  const PER_PAGE = 10
  const pageCount = Math.ceil(createdTotalPage / PER_PAGE)

  useEffect(() => {
    dispatch(getProductList(1, '', type))
  }, [isMedicine, isDiagnostic])

  useEffect(() => {
    if (!user?.isDrugLicenseValid) {
      dispatch(setToggleMedicineStatus(false))
    } else {
      dispatch(setToggleMedicineStatus(true))
      dispatch(setToggleDiagnosticStatus(true))
    }
  }, [])

  useEffect(() => {
    setPageNumbers(createdTotalPage)
    setCurrentPage(createdCurrentPage)
  }, [createdCurrentPage])

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1)
    dispatch(getProductList(selectedPage + 1, '', type))
  }

  const toggleChange = (t: any) => {
    if (t === 'medicine') {
      if (!user?.isDrugLicenseValid) {
        console.log('first open model')
        dispatch(toggledrugLisence(true))
      } else {
        dispatch(setToggleMedicineStatus(!isMedicine))
      }
    } else {
      dispatch(setToggleDiagnosticStatus(!isDiagnostic))
    }
  }

  return (
    <div className="h-full p-3">
      {type === 'mart' && (
        <div className="flex items-center justify-center space-x-5 border-b-2 pb-2">
          <div className="flex items-center space-x-2">
            <span>Medicine</span>
            <Toggle
              name="medicine"
              enableChild="Active"
              disableChild="Inactive"
              checked={isMedicine}
              onChange={() => toggleChange('medicine')}
            />
          </div>
          <div className="flex items-center space-x-2">
            <span>Diagnostics</span>
            <Toggle
              name="diagnostic"
              enableChild="Active"
              disableChild="Inactive"
              checked={isDiagnostic}
              onChange={() => toggleChange('diagnostic')}
            />
          </div>
        </div>
      )}
      {pData?.length > 0 && !loading ? (
        <div className="mt-2 grid grid-cols-2 gap-4 md:grid-cols-4">
          {pData?.map((data: any) => (
            <FeatureProductCard type={type} key={data._id} product={data} />
          ))}
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <div className="flex h-[60vh] items-center justify-center">
          No Data Found
        </div>
      )}

      {pData?.length > 0 && (
        <ReactPagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  )
}

export default SearchProdScreen
