import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice'
import homeReducer from './slices/homeSlice'
import patientReducer from './slices/patientSlice'
import prescriptionSlice from './slices/prescriptionSlice'
import sliderSlice from './slices/sliderSlice'
import modalSlice from './slices/modalSlice'
import notificationSlice from './slices/notificationSlice'
import reportSlice from './slices/reportSlice'
import utilSlice from './slices/utilSlice'
import attendeeProfileSlice from './slices/attendeeProfileSlice'
import cartSlice from './slices/cartSlice'
import testSlice from './slices/testSlice'
import martSlice from './slices/martSlice'
import orderSlice from './slices/orderSlice'
import medDiagnosticSlice from './slices/medDiagnosticSlice'
import productSlice  from './slices/productSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    patient: patientReducer,
    cart: cartSlice,
    mart: martSlice,
    orders: orderSlice,
    // slider: sliderSlice,
    modal: modalSlice,
    notification: notificationSlice,
    prescription: prescriptionSlice,
    // report: reportSlice,
    // util: utilSlice,
    profile: attendeeProfileSlice,
    test: testSlice,
    medDiagnostic: medDiagnosticSlice,
    product : productSlice
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
