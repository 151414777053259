import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Button from '../../Comp/Button'
import { Dialog, Transition } from '@headlessui/react'
import SvgIcon from '../shared/SvgIcon'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../store/slices/cartSlice'
import { toggleCartModal } from '../../store/slices/modalSlice'

const FeatureProductCard = ({ type, product }) => {
    const dispatch = useDispatch();


    return (
        <div className='border bg-white flex flex-col rounded-md relative'>
            <div className='bg-[#E1EDF1] h-40 flex items-center overflow-hidden'>
                <img src={'/images/dummy.png' || product.images?.[0]} className='object-contain mx-auto' alt={product.name} />
            </div>
            <label className='absolute right-0 top-0 w-0 h-0 
  border-l-0 border-l-transparent
  border-t-[75px] border-t-[#FE0944]
  border-r-[75px] border-r-transparent rotate-90'>
            </label>
            <span className='absolute right-1 top-4 rotate-45 text-white text-xs'>{product?.productType}</span>
            <div className='p-2 px-3 mt-1'>
                <p className='text-[#1B1E1F] font-semibold text-sm'>{product.name}</p>
                {
                    type == "mart" ? <div className='flex items-center space-x-4 justify-between'>
                        <div className='flex flex-col'>
                            <span className='text-xs'>Price</span>
                            <span className='font-semibold text-slate-500 text-sm'>₹{product.mrp}</span>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-rose-400 text-xs'>Margin</span>
                            <span className='text-rose-400 font-semibold text-sm'>{product?.marginPercent}%</span>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-xs'>PTR</span>
                            <span className='text-[#6ABF4B] font-semibold text-sm'>₹{product.ptr}</span>
                        </div>
                    </div>
                        : <div className='flex items-center space-x-4 justify-between'>
                            <div className='flex flex-col'>
                                <span className='text-xs'>MRP</span>
                                <span className='font-semibold text-slate-500 text-sm line-through decoration-2'>₹{product.mrp}</span>
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-xs'>Discount</span>
                                <span className='font-semibold text-slate-500 text-sm'>{product?.discountPercent}%</span>
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-xs'>Sale Price</span>
                                <span className='text-[#6ABF4B] font-semibold text-sm'>₹{product?.
                                    salePrice}</span>
                            </div>

                        </div>

                }


                <span onClick={() => dispatch(toggleCartModal(product))} className='flex items-center space-x-2 text-sm bg-[#E6F8FD] p-2 mt-2 w-full justify-center rounded-md font-semibold text-[#03B4EA] cursor-pointer'>
                    <SvgIcon src='/images/cart.svg' className='object-contain h-4 w-4' alt='cart' />
                    <span>Add to Cart</span>
                </span>
            </div>


        </div>
    )
}

export default FeatureProductCard