import { Dialog, Transition } from '@headlessui/react'
import Router from 'next/router'
import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import { addToCart } from '../../store/slices/cartSlice'
import { addToMart } from '../../store/slices/martSlice'
import { toggleCartModal } from '../../store/slices/modalSlice'
import SvgIcon from '../shared/SvgIcon'

export default function CartModal() {
    const dispatch = useAppDispatch()
    const open = useAppSelector((state) => state.modal.cartModal.show)
    const product = useAppSelector((state) => state.modal.cartModal.data)
    const [count, setCount]: any = useState(1);

    console.log("product", product)

    const toggle = () => {
        dispatch(toggleCartModal(null))
    }

    const addItem = async () => {
        Router.pathname.match('/mart')
            ? dispatch(addToMart({ productId: product._id, qty: count }))
            : dispatch(addToCart({ productId: product._id, qty: count }))
    }

    useEffect(() => {
        if (!open) {
            setCount(1)
        }
    }, [open])

    if (!product) return null

    const handleChange = (e: any) => {
        let limit = 3
        setCount(parseInt(e.target.value.slice(0, limit)));
    }



    return (

        <Transition appear show={open} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={toggle}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full single-article max-w-lg transform overflow-hidden rounded-2xl h-auto text-[#1B1E1F] bg-white p-6 text-left align-middle shadow-xl transition-all">

                                <div className='bg-[#E1EDF1] h-44 flex items-center rounded-md overflow-hidden'>
                                    <SvgIcon src={product.images[0] || '/images/dummy.png'} className='object-contain mx-auto' alt='covidselftest' />
                                </div>
                                <div className='mt-2 font-semibold'>
                                    <h1 className='text-2xl'>{product.name}</h1>
                                    {
                                        Router.pathname.match('/mart') ?
                                            <div className='flex items-center space-x-7'>

                                                <div className='flex flex-col'>
                                                    <span className='text-xs'>Price</span>
                                                    <span className='font-semibold text-slate-500 text-lg line-through decoration-2'>₹{product.mrp}</span>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <span className='text-rose-400 text-xs'>Margin</span>
                                                    <span className='text-rose-400 font-semibold text-lg'>{product?.marginPercent}%</span>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <span className='text-xs'>PTR</span>
                                                    <span className='text-[#6ABF4B] font-semibold text-lg'>₹{product.ptr}</span>
                                                </div>



                                            </div> :
                                            <div className='flex items-center space-x-7'>
                                                <div className='flex flex-col'>
                                                    <span className='text-xs'>Price</span>
                                                    <span className='font-semibold text-slate-500 text-lg line-through decoration-2'>₹{product.mrp}</span>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <span className='text-xs'>Sale Price</span>
                                                    <span className='text-[#6ABF4B] font-semibold text-lg'>₹{product?.salePrice}</span>
                                                </div>
                                               

                                            </div>
                                    }

                                    {/* <div className='grid grid-cols-3 text-xs gap-2 mt-2'>
                                        <div className='flex justify-center items-center space-x-2 bg-white p-2 border rounded-md'>
                                            <SvgIcon src="/images/boostImmunity.svg" className='object-contain' alt='boostImmunity' />
                                            <span>Boost Overall
                                                Immunity</span>
                                        </div>
                                        <div className='flex justify-center items-center space-x-2 bg-white p-2 border rounded-md'>
                                            <SvgIcon src="/images/247.svg" className='object-contain' alt='247' />
                                            <span>Boost Overall
                                                Immunity</span>
                                        </div>
                                        <div className='flex justify-center items-center space-x-2 bg-white p-2 border rounded-md'>
                                            <SvgIcon src="/images/safeConsultation.svg" className='object-contain' alt='safeConsultation' />
                                            <span>Boost Overall
                                                Immunity</span>
                                        </div>
                                    </div> */}
                                    <h1 className='mt-4'>Product Description</h1>
                                    {
                                        product.description ? <p className='mt-2 modalDesc'>{product.description}</p> :
                                            <p className='mt-2'>Not Available</p>
                                    }

                                    <div className='flex items-center justify-between space-x-7 mt-2 bg-white border-t-2 pt-1'>
                                        <SvgIcon onClick={toggle} src="/images/cut.svg" className='object-contain cursor-pointer' alt='cut' />
                                        <div className='flex items-center space-x-3'>
                                            <div className='flex space-x-6 justify-between items-center border p-2 rounded-md px-4'>
                                                <SvgIcon onClick={() => setCount(c => c - 1 <= 1 ? 1 : c - 1)} src='/images/subtraction.svg' className='object-contain w-4 h-4 cursor-pointer' alt='subtraction' />
                                                {/* <span className='text-base font-semibold'>{count}</span> */}
                                                <input className='mycartinput' type="number" value={count} onChange={(e) => handleChange(e)} />
                                                <SvgIcon onClick={() => setCount(c => c + 1 || 0)} src='/images/addition.svg' className='object-contain w-4 h-4 cursor-pointer' alt='addition' />
                                            </div>
                                            <button className='p-3 w-fit flex items-center space-x-2 bg-blueColor rounded-md text-white'>
                                                <SvgIcon src='/images/addtocartwhite.svg' className='object-contain w-4 h-4' alt='addtocartwhite' />
                                                <span onClick={addItem}>Add To Cart</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
