import { useRouter } from 'next/router'
import React from 'react'
import SearchCard from './attendee/SearchCard';
import Avatar from './shared/Avatar';

const SearchScreen = ({ patientList, onClose }: { patientList: any, onClose: any }) => {
    const router = useRouter();

    // const handleSearch = (id: string) => {
    //     onClose();
    //     router.push({
    //         pathname: '/platform/patients/[id]',
    //         query: { id },
    //     });
    // }

    return (
        <div className="h-full bg-[#F5FAFD] p-3">
            <div className="grid grid-cols-3 gap-4 mt-2">
                {patientList.map((pres: any) => <SearchCard key={pres._id} item={pres} />)}
            </div>
        </div>
    )
}

export default SearchScreen
