import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className='h-[70vh] flex items-center justify-center'>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#03B4EA', '#03B4EA', '#03B4EA', '#03B4EA', '#03B4EA']}
            />
        </div>
    )
}

export default Loader