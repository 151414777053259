import React from 'react'
import classNames from 'classnames'
const SvgIcon = ({
  src,
  className,
  alt,
  onClick,
}: {
  src: string
  className?: string
  alt?: string
  onClick?: () => void
}) => {
  return (
    <img src={src} onClick={onClick} className={classNames(className || 'h-6 w-6')} alt={alt} />
  )
  return <svg href={src} className={classNames('svg-icon', className)} />
}

export default SvgIcon
