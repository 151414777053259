import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuAlt2Icon, PlusSmIcon, ShoppingCartIcon,MicrophoneIcon } from '@heroicons/react/outline'
import ToggleButton from '../buttons/ToggleButton'
import classNames from 'classnames'
import SvgIcon from '../shared/SvgIcon'
import Avatar from '../shared/Avatar'
import { useDispatch } from 'react-redux'
import { getCartData } from '../../store/slices/cartSlice'
import { getProfile } from '../../store/slices/attendeeProfileSlice'
import { useAppSelector, useAppDispatch } from '../../hooks/home'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { getMartData } from '../../store/slices/martSlice'
import { getCartDiagnosticData } from '../../store/slices/medDiagnosticSlice'
import { togglediagnosticUpload } from '../../store/slices/modalSlice'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const user = {
    name: 'Whitney Francis',
    email: 'whitney.francis@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
    { name: 'View Profile', href: '/profile' },
    { name: 'Sign out', href: '/auth/logout' },
]

const Header = (props: {
    mobileMenuOpen: any
    setMobileMenuOpen: any
    search: string
    setSearch: Function
}): JSX.Element => {
    const [files, setFiles]: [s: any, s: any] = useState([]);
    const dispatch = useAppDispatch();
    const cartData = useAppSelector(state => state.cart?.cartData)
    const martData = useAppSelector(state => state.mart?.cartData)
    const profileData = useAppSelector(state => state.profile?.data)
    const authData = useAppSelector(state => state.auth?.user)
    const cartDiagnosticData = useAppSelector((state) => state.medDiagnostic?.cartData?.cartDetails?.appointmentCart[0]?.appointment)
    const Router = useRouter()
    const [isListening, setIsListing] = useState(false);

    const { transcript, resetTranscript } = useSpeechRecognition();

    // const handleSearch = () => {
    //     props.setSearch(transcript)
    //     // resetTranscript();
    //     // Call your search function with the search term (e.g., API call)
    // };

    useEffect(() => {
        if (transcript) {
            props.setSearch(transcript)
        }
    }, [transcript])

   

    const { pathname } = useRouter()
    const uploadRef: any = useRef();


    useEffect(() => {
        if (Router.pathname.match('/products')) {
            dispatch(getCartData())
        }
        if (Router.pathname.match('/mart')) {
            dispatch(getMartData())
        }
    }, [Router.pathname])

    useEffect(() => {
        dispatch(getProfile(authData?._id))
        dispatch(getCartDiagnosticData())
    }, [])

    const getProductsLength = useCallback(() => {
        if (Router.pathname.match('/products')) {
            return cartData?.cartDetails?.cart?.[0]?.products.length
        }
        if (Router.pathname.match('/mart')) {
            return martData?.cartDetails?.mart?.[0]?.products.length
        }
    }, [cartData, martData, Router.pathname])

    useEffect(() => {
        if (files.length) {
            dispatch(togglediagnosticUpload([URL.createObjectURL(files[0]), authData?._id]))
        }

    }, [files])

    return (
        <header className="w-full">
            <div className=" relative z-10 flex h-16 flex-shrink-0 items-center  bg-blueColor shadow-sm md:px-8">
                <button
                    type="button"
                    className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    onClick={() => props.setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div>
                    <img
                        src="/images/logo.png"
                        alt="EONMED"
                    />
                </div>

                {/* <div className="flex border-white  justify-between px-4 sm:px-6"> */}
                {/* <div className="m-auto flex-1 flex"> */}
                <div className="flex flex-1 items-center   sm:px-6  ">
                    <div className="relative mx-auto my-auto h-10 w-32 md:w-96  rounded-md border border-white text-white sm:px-6 md:block ">
                        <input
                            onChange={(e) => props.setSearch(e.target.value)}
                            value={props.search}
                            name="search-field"
                            id="search-field"
                            className="h-full w-full bg-transparent py-2 pr-8 pl-1 text-base placeholder-white focus:border-transparent   focus:ring-0"
                            placeholder={(Router.asPath === "/products" || Router.asPath === "/mart") ? "Search Products By Name" : Router.asPath === "/diagnostics" ? "Search Test Result" : "Search Patient By Name"}
                            type="search"
                        />
                        <div className="absolute inset-y-0 right-2 flex space-x-2 items-center">
                            <SearchIcon
                                className="h-5 w-5 flex-shrink-0 pointer-events-none"
                                aria-hidden="true"
                            /> 
                           
                           <MicrophoneIcon onClick={SpeechRecognition.startListening} className='h-5 w-5 flex-shrink-0 cursor-pointer' />
                        </div>
                        

                    </div>
                   

                </div>
                <div className="ml-auto flex items-center space-x-4 sm:space-x-6 sm:pr-6 md:pr-0 pr-3">
                    {/* Profile dropdown */}
                    {
                        pathname.includes('/diagnostics') &&
                        <div className='flex items-center'>
                            <input onChange={(e: any) => setFiles(Array.from(e.target.files))} multiple ref={uploadRef} hidden name='files' type='file' />
                            <div onClick={() => uploadRef.current.click()} className='flex items-center p-1 space-x-1 bg-[#D73A3A] rounded-full text-xs mr-2 cursor-pointer'>
                                <SvgIcon src="/images/uploadPrescription.svg" alt="uploadPrescription" className='object-contain' />
                                <span className='text-white'>Upload Prescription</span>
                            </div>
                        </div>
                    }

                    <Menu as="div" className="relative flex-shrink-0">
                        <div>
                            <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span className="sr-only">Open user menu</span>
                                {
                                    profileData?.images ? <img className='w-9 h-9 object-cover rounded-full' src={profileData?.images} alt='profilepics' />
                                        : <img
                                            src="/images/person_avatar.png"
                                            alt="EONMED"
                                        />
                                }


                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-midnight py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <Link href={item.href}>
                                                <span
                                                    className={classNames(
                                                        'hover:bg-gray-100 hover:text-midnight', 'text-white',
                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                    )}


                                                >
                                                    {item.name}
                                                </span>
                                            </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    {
                        (
                            Router.pathname.match('/products')
                            ||
                            Router.pathname.match('/mart')
                        )
                        &&
                        <Link href={Router.pathname.match('/products') ? `/products/cart` : `/mart/cart`} >
                            <div className='relative  cursor-pointer'>
                                <ShoppingCartIcon className='h-6 w-6 text-white' />
                                <span className='rounded-full bottom-3 left-3 text-white font-semibold absolute p-1'>
                                    {getProductsLength()}
                                </span>
                            </div>
                        </Link>
                    }
                    {pathname.includes('/diagnostics') && <Link href={"/diagnostics/cart"} >
                        <div className='flex items-center space-x-0 cursor-pointer'>
                            <ShoppingCartIcon className='h-6 w-6 text-white' />
                            {cartDiagnosticData && cartDiagnosticData.length > 0 &&
                                <span className='bg-[#343E45] text-white px-2 py-1 rounded-full flex items-center justify-center h-7'>{cartDiagnosticData?.length}</span>
                            }

                        </div>
                    </Link>

                        // <Link href={"/mycart"} >
                        //     <div className='relative  cursor-pointer'>
                        //         <ShoppingCartIcon className='h-6 w-6 text-white' />
                        //         <span className='rounded-full bottom-3 left-3 text-white font-semibold absolute p-1'>
                        //             {cartData?.cartDetails?.cart?.[0]?.products.length}
                        //         </span>
                        //     </div>
                        // </Link>
                    }



                    {/* <button
            type="button"
            className="flex items-center justify-center rounded-full bg-indigo-600 p-1 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
            <span className="sr-only">Add file</span>
          </button> */}
                </div>
                {/* </div> */}
            </div>
        </header>
    )
}

export default Header
