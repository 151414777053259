import axios from 'axios'
import { notifier } from '../store/slices/notificationSlice'

const instance = axios.create({
  baseURL: process.env.API_URL,
})
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token && (!config.headers || !config.headers.Authorization)) {
    config.headers.Authorization = 'bearer ' + token
  }

  return config
})

instance.interceptors.response.use(function (config) {
  //console.log('response', config)
  return config
})

export const callApi = (apiPromise) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await apiPromise())
    } catch (err) {
      let message = 'Network request fail'
      if (err.response) message = err.response.data.error[0].msg
      notifier.error(message)
      reject(err)
    }
  })
}

export default instance
