import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type HomeState = {
  loading: boolean
  model:boolean
  error: any | null
  data: {
    consultations: Number
    orders: Number
    tests: Number
    graphData: any[]
    tableData: any[]

  }
}

const initialState: HomeState = {
  error: null,
  model:false,
  data: {
    consultations: 0,
    orders: 0,
    tests: 0,
    graphData: [],
    tableData: []
  },
  loading: true,
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setModel: (state, action: PayloadAction<boolean>) => {
      state.model = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.data.consultations = action.payload.consultations || 0
      state.data.orders = action.payload.orders || 0
      state.data.tests = action.payload.tests || 0
      state.data.graphData = action.payload.graphData || []
      state.data.tableData = action.payload.tableData || []
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setLoading,
  setModel,
  setData,
  setError,
} = homeSlice.actions

//API Calls
export const getData =
  (filter) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.post('/franchisee/dashboard', filter)
      const data = response.data.response.data
      dispatch(setData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default homeSlice.reducer
