import { format } from 'date-fns';
import React from 'react';
import SvgIcon from '../shared/SvgIcon';
import classNames from 'classnames';
import { getPatients, setSelectedPatient } from '../../store/slices/patientSlice';
import Router, { useRouter } from 'next/router';
import { useAppDispatch } from '../../hooks/home';
import {setModel} from '../../store/slices/homeSlice'
import { togglePdfModal } from '../../store/slices/modalSlice'

export default function SearchCard({ item: pres }) {
    const router = useRouter()
    const dispatch = useAppDispatch()

    const goToHistory = (pres: any) => {
        //dispatch(setSelectedPatient(pres))
        dispatch(togglePdfModal(pres.prescription_pdf))
        //router.push('/patients/history')
        //dispatch(setModel(false))
    }

    return <div
        className='card rounded-md border bg-white p-3 cursor-pointer'
        onClick={() => goToHistory(pres)}
    >
        <div className='flex justify-between'>
            <div className="flex space-x-2 items-center">
                <div
                    className={classNames('bg-cyan-100',
                        'mr-3 inline-flex h-8 w-8 items-center justify-center rounded-full p-6 shadow'
                    )}
                >
                    {pres.patients?.firstName?.slice(0, 1)}
                    {pres.patients?.lastName?.slice(0, 1)}
                </div>
                <div className="flex flex-col">
                    <span className="text-lg font-bold text-blueColor">{pres.patients?.firstName + " " + pres.patients?.lastName}</span>
                    <span className="text-xs text-[#000000]">+91 {pres.patients?.phone}</span>
                </div>
            </div>
            <span className='text-[#000000] text-sm'>{pres.createdAt ? format(new Date(pres.createdAt), 'MMM dd, yyyy h:mm a') : ''}</span>
        </div>
        <div className="mt-3 flex justify-between">
            {pres.isVideoConsultationDone == true &&
                <p className='bg-[#03B4EA33] text-[#175069] py-2 px-4 rounded-md'>Consultation Completed</p>
            }
            {pres.isVideoConsultationDone == false &&
                <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Consultation Not Completed</p>
            }
            {pres.isPaid == true &&
                <p className='bg-[#6ABF4B33] text-[#325C23] py-2 px-4 rounded-md'>Payment Completed</p>
            }
            {pres.isPaid == false &&
                <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Payment Not Completed</p>
            }
        </div>
    </div>
    // return (
    //     <div className='border rounded-md bg-white p-4'>
    //         <div className='flex items-center space-x-3'>
    //             <img className='w-11 h-11 object-contain' src='/images/profilepic.png' alt='profilepic' />
    //             <div className='flex-1 flex flex-col text-[#003B4D] font-bold'>
    //                 <p className='text-lg'>{item.firstName} {item.lastName}</p>
    //                 <span className='text-xs font-semibold'>{format(new Date(item.createdAt), "MMMM dd,yyyy hh:mm a")}</span>
    //             </div>
    //         </div>
    //         <div className='flex flex-col space-y-2 mt-4'>
    //             <div className='flex items-center space-x-2 text-xs text-white'>
    //                 <SvgIcon src='/images/profilePhone.svg' className='w-4 h-4' alt='profilePhone' />
    //                 <p className='font-bold text-[#003B4D]'>{item.phone || item.memberPhone}</p>
    //             </div>
    //             <div className='flex items-center space-x-2 text-xs text-white'>
    //                 <SvgIcon src='/images/profileEmail.svg' className='w-4 h-4' alt='profileEmail' />
    //                 <p className='font-bold text-[#003B4D]'>{item.email}</p>
    //             </div>
    //             <div className='flex items-center space-x-2 text-xs text-white'>
    //                 <SvgIcon src='/images/profileLocation.svg' className='w-4 h-4' alt='profileLocation' />
    //                 <p className='font-bold text-[#003B4D]'>{item.city} {item.state && ","} {item.state}</p>
    //             </div>
    //         </div>
    //         {/* <div className='flex justify-between items-center font-bold p-1 px-2 bg-[#F4F9FB] text-xs mt-2'>
    //             <span>Refer</span>
    //             <span> {refer}</span>
    //         </div>
    //         <div className='flex justify-between items-center font-bold p-1 px-2 bg-[#F4F9FB] text-xs mt-1'>
    //             <span>Spacialist</span>
    //             <span>{specialist}</span>
    //         </div> */}

    //     </div>
    // )

}