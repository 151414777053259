import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'

// declaring the types for our state
export type OrdersState = {
  loading: boolean
  error: any | null
  orders: any[]
  selectedOrder: any | null
}

const initialState: OrdersState = {
  error: null,
  loading: true,
  orders: [],
  selectedOrder: null,
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.orders = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setSelectedOrder: (state, action: PayloadAction<any>) => {
      state.selectedOrder = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError, setSelectedOrder } =
  orderSlice.actions

//API Calls

export const getOrders =
  (type: 'attendee' | 'products' | 'diagnostics',Query?:string | "",date?: string | null) => async (dispatch: any) => {
    if (type === 'attendee') {
      dispatch(getMartOrders(Query,date))
    }
    if (type === 'products') {
      dispatch(getProductOrders(Query,date))
    }
    if (type === 'diagnostics') {
      dispatch(getDiagnosticOrders(Query,date))
    }
  }

const getMartOrders =
  (Query?:string | "",date?: string | null) => async (dispatch: (arg0: { payload: any; type: string }) => string) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(`/franchisee/orders?type=mart&orderNumber=${Query}&date=${date}`)
      const orders = response.data.response.data
      dispatch(setData(orders))
      dispatch(setLoading(false))
    } catch (err) {
      console.error(err)
      dispatch(setError(err))
      dispatch(setLoading(false))
    }
  }

const getProductOrders =
  (Query?:string | "",date?: string | null) => async (dispatch: (arg0: { payload: any; type: string }) => string) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(`/franchisee/orders?orderNumber=${Query}&date=${date}`)
      const orders = response.data.response.data
      dispatch(setData(orders))
      dispatch(setLoading(false))
    } catch (err) {
      console.error(err)
      dispatch(setError(err))
      dispatch(setLoading(false))
    }
  }

const getDiagnosticOrders =
  (Query?:string | "",date?: string | null) => async (dispatch: (arg0: { payload: any; type: string }) => string) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(`/appointmentByClinic?orderNumber=${Query}&date=${date}`)
      const orders = response.data.response.data
      dispatch(setData(orders))
      dispatch(setLoading(false))
    } catch (err) {
      console.error(err)
      dispatch(setError(err))
      dispatch(setLoading(false))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default orderSlice.reducer
