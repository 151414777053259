import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import SvgIcon from '../shared/SvgIcon'
import { XCircleIcon } from '@heroicons/react/solid'
import { notifier } from '../../store/slices/notificationSlice'
import { getList, addTestToCart } from '../../store/slices/medDiagnosticSlice'

import { useRouter } from 'next/router'

export default function DiagnosticSearchModel({ search, open, setSearch, setOpen, onClose = () => { }, diagnosticList }) {
    const dispatch = useAppDispatch()
    const { pathname } = useRouter()
    const modelOpen = useAppSelector(state => state.home?.model)
    const router = useRouter()

    const closeModal = () => {
        setSearch("")
        setOpen(false)
    }

    const addItem = (data) => {
        let val = { testId: '', clinicId: '' }
        val.testId = data.testId
        val.clinicId = data.clinic.clinicId
        dispatch(addTestToCart(val))
        notifier.success('Added to cart')
    }




    return (

        <Transition appear show={modelOpen} as={Fragment} >
            <Dialog className="relative" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-2 mt-12 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full ml-28 h-full min-h-[86vh] single-article transform overflow-hidden rounded-2xl text-[#1B1E1F] p-2 text-left align-middle shadow-xl transition-all">
                                <span
                                    className="flex justify-end"
                                >
                                    <span className="sr-only">Close</span>
                                    <XCircleIcon onClick={closeModal} className="h-6 w-6 cursor-pointer" aria-hidden="true" />
                                </span>


                                <div>



                                    {!pathname.includes('/products') &&
                                        <div>
                                            {search && diagnosticList.length > 0 ? (

                                                <div className='grid grid-cols-3 gap-4 w-full'>
                                                    {
                                                        diagnosticList.map((item, i) => (
                                                            <div onClick={() => router.push(`/diagnostics/detail/${item.clinic.clinicId}/${item.testId}`)} key={i} className='bg-white border rounded-md shadow-md p-3 cursor-pointer'>
                                                                <h1 className='text-[#343E45] font-bold'>{item.testName}</h1>
                                                                {item.testIds && item.testIds.length > 0 &&
                                                                    <p className='text-[#343E45]'>{`includes ${item.testIds.length} tests`}</p>}
                                                                <div className='flex items-center space-x-1 mt-2 mb-3'>
                                                                    <span className='text-[#4E9932] font-bold'>{item.salePrice}</span>
                                                                    <span className='text-xs text-[#707070] line-through'>{item.mrp}</span>
                                                                    <span className='bg-[#FF9E62] rounded-full text-xs p-1 px-2 text-white'>{item.discount} %</span>
                                                                </div>
                                                                <span onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    addItem(item)
                                                                    notifier.success('Added to cart')
                                                                }} className='bg-blueColor p-2 text-white text-xs rounded-md mt-2 cursor-pointer'>Add To Cart</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                                : <div className='flex items-center justify-center'>Currrently No Test Available in Your Area</div>
                                            }

                                        </div>
                                    }
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
