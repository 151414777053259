import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '..'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'
import Router from 'next/router'
import Search from 'antd/lib/input/Search'


// declaring the types for our state
export type productState = {
    loading: boolean
    error: any | null
    data: {
        data: any | null
        total: any
        current: any
    },
    toggleStatus : {
        medicine : boolean,
        diagnostic : boolean
    }
}

const initialState: productState = {
    error: null,
    loading: true,
    data: {
        data: [],
        total: 0,
        current: 0
    },
    toggleStatus : {
        medicine : false,
        diagnostic : false
    }
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setProductData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.data = action.payload
        },
        setCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.current = action.payload
        },
        setTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.total = action.payload
        },
        setToggleMedicineStatus: (state, action: PayloadAction<any>) =>{
            state.toggleStatus.medicine = action.payload
        },
        setToggleDiagnosticStatus: (state, action: PayloadAction<any>) =>{
            state.toggleStatus.diagnostic = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setProductData, setError,
    setCurrent, setTotal , setToggleMedicineStatus, setToggleDiagnosticStatus} = productSlice.actions


export const getProductList =
    (val: any,Search:string ,type) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const isMedicine = store.getState().product?.toggleStatus?.medicine
                const isdiagnostic = store.getState().product?.toggleStatus?.diagnostic
                const response = await axios.get(`/product/paginate?activePage=${val}&activeLimit=100&name=${Search}&mart=${type === "mart" ? true : false}&medicine=${isMedicine}&diagnostic=${isdiagnostic}`)
                const res = response.data.response
                dispatch(setProductData(res.data))
                dispatch(setCurrent(res?.pageDetail.page))
                dispatch(setTotal(res?.pageDetail?.totalPages))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                dispatch(setLoading(false))
            }
        }

export default productSlice.reducer
