import { Socket, io } from 'socket.io-client'

class SocketService {
  public static initialised = false
  public static socket: Socket

  static init() {
    if (this.socket) return this.socket;
    this.socket = io(`${process.env.BASE_URL}`, {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
    })
    return this.socket
  }
}

export default SocketService
