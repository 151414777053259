import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'
import * as Sentry from '@sentry/browser'
import { setLogin } from './authSlice'

// declaring the types for our state
export type ProfileState = {
  loading: boolean
  error: any | null
  data: any
}

const initialState: ProfileState = {
  error: null,
  loading: true,
  data: null,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.data = action.payload
      //Update in Session
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError } = profileSlice.actions

//API Calls
export const getProfile =
  (id: string) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(`franchisee/members/${id}`)
      const data = response.data.response.data
      dispatch(setData(data))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const updateProfile =
  (id: string, data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch(`franchisee/members/${id}`, data)
      const resData = response.data.response.data
      // console.log('redartaa', resData)
      dispatch(setData(resData))
      dispatch(setLoading(false))
      notifier.success(response.data.response.msg)
    } catch (error) {
      dispatch(setError(error))
      notifier.error('Upload Error , Try Again !')
    }
  }

export const changePassword =
  (data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      const response = await axios.post(
        `/settings/franchisee/member/password `,
        data
      )
      const res = response.data.response.data
      notifier.success(response.data.response)
      window.location.href = '/'
    } catch (error) {
      dispatch(setError(error))
      notifier.error(
        'Old password is incorrect, Please provide the correct password !'
      )
    }
  }

export const payForServices = (amount) => async (dispatch: any) => {
  let user = JSON.parse(localStorage.getItem('user') || '')
  const franchisee = user.franchiseeId
  // @ts-ignore
  if (!window.Razorpay) {
    const script = document.createElement('script')
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'
    script.async = true
    document.body.appendChild(script)
  }
  const params = { amount }
  try {
    const response = await axios.post(`/createProfilePaymentOrder`, params)
    const data = response.data.response.data
    if (data?.id) {
      const options = {
        key: process.env.RAZORPAY_KEY,
        amount: data.amount * 100,
        name: 'EONBT',
        description: '',
        order_id: data.id,
        prefill: {
          name: '',
          email: 'tech@eonmed.in',
          contact: user.phone,
        },
        theme: {
          color: '#528ff0',
        },
        async handler(response) {
          notifier.success(
            'You have been registered successfully with EONMED. Thank you !'
          )

          await axios.patch(`/franchisee/${franchisee}`, {
            registered: true,
            registrationFee: params.amount,
            paymentId: response.razorpay_payment_id,
            payment_signature: response.razorpay_signature,
          })
          user.registered = true
          // localStorage.setItem('user', JSON.stringify(user))
          dispatch(
            setLogin({
              accessToken: localStorage.getItem('token'),
              user,
            })
          )

          // await dispatch(updateStatsforPrescription(pres))
          Sentry.captureMessage(
            `Payment successfull for Profile - ${franchisee}`
          )

          window.location.href = '/'
        },
      }
      //@ts-ignore
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      paymentObject.on('payment.failed', function (response) {
        window.location.reload()
        console.error({
          message: 'Payment Failed',
          description: `Payment Failed. Reason : ${response.error.reason}`,
        })
      })
    }
  } catch (e) {
    notifier.error(
      'Unable to proceed for payment. Please contact the system admin !'
    )
    return
  }
}

export const updateProfileData =
  (id: string, data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch(`franchisee/${id}`, data)
      dispatch(setLoading(false))
      notifier.success(response.data.response.msg)
    } catch (error) {
      dispatch(setError(error))
      notifier.error('Upload Error , Try Again !')
    }
  }

// exporting the reducer here, as we need to add this to the store
export default profileSlice.reducer
