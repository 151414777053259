import axios from '../../utils/axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '../index'
import { notifier } from './notificationSlice'
import * as Sentry from '@sentry/browser'
import { getOnePatient, getPatients, setSelectedPatient } from './patientSlice'
import { getProfile } from './attendeeProfileSlice'

// declaring the types for our state
export type PrescriptionState = {
  //Prescripton
  loading: Boolean
  prescriptions: any[]
  selectedPrescription: any | null
  error: any | null
  //Prescription History
  loadingHistory: Boolean
  history: any[]
  errorHistory: any | null
  //Submissions
  submissionLoading: Boolean
  submissionError: any | null
  submissionData: any[]
  disClaimerModal : Boolean
}

const initialState: PrescriptionState = {
  //Prescripton
  loading: true,
  prescriptions: [],
  selectedPrescription: null,
  error: null,
  //Prescription History
  loadingHistory: false,
  history: [],
  errorHistory: null,
  //Submissions
  submissionLoading: false,
  submissionError: null,
  submissionData: [],
  disClaimerModal :false
}

export const PrescriptionSlice = createSlice({
  name: 'prescriptions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    //Prescripton
    setLoading: (state, action: PayloadAction<Boolean>) => {
      state.loading = action.payload
    },
    setPrescriptions: (state, action: PayloadAction<any[]>) => {
      state.prescriptions = action.payload
    },
    setSelectedPrescription: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = action.payload
      state.history = !action.payload ? [] : state.history
    },
    updateSelectedPrescription: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...(state.selectedPrescription || {}),
        ...action.payload,
        patientId: state.selectedPrescription?.patientId,
        symptomsId: state.selectedPrescription?.symptomsId,
      }
    },
    setPatient: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...state.selectedPrescription,
        patientId: action.payload,
      }
    },
    setSymptoms: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...state.selectedPrescription,
        symptomsId: action.payload,
      }
    },

    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    //Prescription History
    setLoadingHistory: (state, action: PayloadAction<Boolean>) => {
      state.loadingHistory = action.payload
    },
    setHistory: (state, action: PayloadAction<any[]>) => {
      state.history = action.payload
    },
    setErrorHistory: (state, action: PayloadAction<any>) => {
      state.errorHistory = action.payload
    },
    //Submissions
    setSubmissionLoading: (state, action: PayloadAction<Boolean>) => {
      state.submissionLoading = action.payload
    },
    setSubmissionError: (state, action: PayloadAction<any>) => {
      state.submissionError = action.payload
    },
    setSubmissionData: (state, action: PayloadAction<any>) => {
      state.submissionData = action.payload
    },
    setDisClaimerModal :(state, action: PayloadAction<any>) =>{
      state.disClaimerModal = action.payload
    }

  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  //Prescripton
  setLoading,
  setPrescriptions,
  setSelectedPrescription,
  setError,
  updateSelectedPrescription,
  setPatient,
  setSymptoms,
  //Prescription History
  setLoadingHistory,
  setHistory,
  setErrorHistory,
  //Submissions
  setSubmissionLoading,
  setSubmissionError,
  setSubmissionData,
  setDisClaimerModal,
} = PrescriptionSlice.actions

//API Calls
export const getConsultationData =
  (id: string) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const pres = store.getState().prescription.selectedPrescription
        if (!pres) dispatch(setLoading(true))
        const response = await axios.get(`/prescription/${id}`)
        const prescription = response.data.response.data
        // console.log({ prescription })
        dispatch(setSelectedPrescription(prescription))
        dispatch(setLoading(false))
        // return prescription //For outside use as a promise
      } catch (err) {
        console.error(err)
        // dispatch(setError(err))
        dispatch(setLoading(false))
      }
    }

export const getConsultationHistory =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoadingHistory(true))
      const prescription = store.getState().prescription.selectedPrescription
      const response = await axios.get(
        `/consultation_history/${prescription._id}`
      )
      const prescriptions = response.data.response.data
      dispatch(setHistory(prescriptions))
      dispatch(setLoadingHistory(false))
    } catch (err) {
      console.error(err)
      dispatch(setLoadingHistory(false))
    }
  }

export const getPrescriptions =
  (type: 'review' | 'reviewed') => async (dispatch: any) => {
    try {
      let query = ''
      if (type === 'review') query = '?status=Review'
      if (type === 'reviewed') query = '?status=Reviewed'
      dispatch(setLoading(true))
      const response = await axios.get('/prescription/' + query)
      const data = response.data.response.data
      dispatch(setPrescriptions(data))
      dispatch(setSelectedPrescription(data[0]))
      dispatch(setLoading(false))
    } catch (error) {
      // dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const updatePatient =
  (id: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        data.patientId = id
        const response = await axios.patch('/patients', data)
        const patient = response.data.response.data
        // console.log({ patient })
        dispatch(setPatient(patient))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const updatePrescription =
  (id: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const response = await axios.patch('/prescription/' + id, data)
        const prescription = response.data.response.data
        // console.log({ prescription })
        dispatch(updateSelectedPrescription(prescription))
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const addDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.post(`/prescription/add/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const updateDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.post(`/prescription/add/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const deleteDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.post(`/prescription/delete/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const updateMedicalRecord =
  (data: any) => async (dispatch: (arg0: any) => void) => {
    try {
      const prescription = store.getState().prescription.selectedPrescription
      const response = await axios.patch(`/patients`, {
        patientId: prescription.patientId._id,
        medicalRecords: data,
      })
      dispatch(setPatient(response.data.response.data))
      notifier.success(response.data.response.msg)
    } catch (error) {
      console.error(error)
      // dispatch(setError(error))
    }
  }

export const updateSymptoms =
  (symptoms: any[], otherSymptoms: any[]) =>
    async (dispatch: (arg0: any) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const symptom_response = await axios.post(`/patientSymptoms`, {
          prescriptionId: prescription._id,
          otherUnderlyingConditions: otherSymptoms,
          hopi_symptoms: symptoms,
        })
        const symptomsDoc = symptom_response.data.response.data
        dispatch(setSymptoms(symptomsDoc))
        dispatch(
          updatePrescription(prescription._id, { symptomsId: symptomsDoc })
        )
        notifier.success(symptom_response.data.response.msg)
      } catch (error) {
        console.error(error)
        // dispatch(setError(error))
      }
    }

export const uploadFiles =
  (pid: string, files: any[]) => async (dispatch: any) => {
    try {
      const formData = new FormData()
      formData.append('folder', 'user_reports')
      formData.append('prescriptionId', pid)
      // console.log(files, 'files')
      Array.from(files).map((item: any, i) => {
        formData.append(`file${i}`, item)
      })
      const token = localStorage.getItem('token')
      const response = await axios.post(
        `/prescription/upload/files`,
        formData,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      )
      const uploadList = response.data.response.data.filesShared || []
      notifier.success(response.data.response.msg)
      return uploadList
    } catch (err: any) {
      notifier.error(err.response.data.error?.[0].msg)
      console.error(err)
    }
  }

//Folowups
export const getSubmissionReport =
  (prescription: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch('/followup/submissions/report', {
        prescriptionId: prescription._id,
        days: 11,
      })
      const data = response.data.response.data
      dispatch(setSubmissionData(data))
      dispatch(setLoading(false))
    } catch (error) {
      // dispatch(setError(error))
    }
  }

//Deactivation
export const deactivatePrescription = () => async (dispatch: any) => {
  try {
    const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.patch(
      `/prescription/deactivate/${prescription._id}`
    )
    const data = response.data.response.data
    dispatch(updateSelectedPrescription(data))
    notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    // dispatch(setError(error))
  }
}

export const getPrescriptionAmount = async () => {
  try {
    const response = await axios.get(`/productByCode/SCC`)
    const data = response.data.response.data
    const amount = data.pricePerUnit
    return amount
  } catch (err) {
    console.error(err)
  }
}

export const updateStatsforPrescription = (pres) => async (dispatch: any) => {
  try {
    await dispatch(
      updatePrescription(pres._id, {
        isPaid: true,
        amountPaid: 0,
      })
    )
    const res = await axios.patch(`/commission/stats/update`, {
      prescriptionId: pres._id,
    })
    // console.log('Comission Stats Updated', res.data)
  } catch (err) {
    console.error(err)
  }
}

export const payForPrescription =
  (pres, price, setLoading = null) =>
    async (dispatch: any) => {
      if (pres.freeConsultation || pres.isPaid) {
        return Sentry.captureMessage(`Free consultation (no payment) ${pres._id}`)
      }
      const amount = price || (await getPrescriptionAmount())
      if (!amount) {
        return dispatch(updateStatsforPrescription(pres))
      }
      if (setLoading) {
        // @ts-ignore
        setLoading(true)
      }
      // @ts-ignore
      if (!window.Razorpay) {
        const script = document.createElement('script')
        script.src = 'https://checkout.razorpay.com/v1/checkout.js'
        script.async = true
        document.body.appendChild(script)
      }
      Sentry.captureMessage(`Payment button click - ${pres._id}`)
      const params = { prescriptionId: pres._id, amount }
      const response = await axios.post(`/createRazorpayPrescription`, params)
      const data = response.data.response.data
      const options = {
        key: process.env.RAZORPAY_KEY,
        amount: data.amount * 100,
        name: 'EONBT',
        description: '',
        order_id: data.id,
        prefill: {
          name: '',
          email: 'invoices@eonmed.in',
          contact: pres.patientId.phone,
        },
        theme: {
          color: '#528ff0',
        },
        async handler(response) {
          await dispatch(
            updatePrescription(pres._id, {
              isPaid: true,
              amountPaid: params.amount,
              paymentId: response.razorpay_payment_id,
              payment_signature: response.razorpay_signature,
            })
          )
          // await dispatch(updateStatsforPrescription(pres))
          await Promise.all([
            dispatch(getPatients('active')),
            dispatch(getOnePatient(pres.patientId._id)),
          ])
          if (setLoading) {
            // @ts-ignore
            setLoading(false)
          }
          Sentry.captureMessage(`Payment successfull - ${params.prescriptionId}`)
        },
      }
      //@ts-ignore
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      paymentObject.on('payment.failed', function (response) {
        window.location.reload()
        console.error({
          message: 'Payment Failed',
          description: `Payment Failed. Reason : ${response.error.reason}`,
        })
      })
    }



export const payForFranchiseePrescription = (amount) => async (dispatch: any) => {
  let user = JSON.parse(localStorage.getItem('user') || '')
  const franchisee = user.franchiseeId
  // @ts-ignore
  if (!window.Razorpay) {
    const script = document.createElement('script')
    script.src = 'https://checkout.razorpay.com/v1/checkout.js'
    script.async = true
    document.body.appendChild(script)
  }
  const params = { amount }
  try {
    const response = await axios.post(`/createFranchiseePrescriptionOrder`, params)
    const data = response.data.response.data
    if (data?.id) {
      const options = {
        key: process.env.RAZORPAY_KEY,
        amount: data.amount * 100,
        name: 'EONBT',
        description: '',
        order_id: data.id,
        prefill: {
          name: '',
          email: 'tech@eonmed.in',
          contact: user.phone,
        },
        theme: {
          color: '#528ff0',
        },
        async handler(response) {
          notifier.success(
            'You have been registered successfully with EONMED. Thank you !'
          )

          await axios.patch(`/franchisee/${franchisee}`, {
            consultationCredit: {
              registrationFee: params.amount,
              paymentId: response.razorpay_payment_id,
              payment_signature: response.razorpay_signature,
              amountPaid: amount,
              payment_order_id: response.razorpay_order_id,
              consultationCreditDate: new Date(),
            },
            consultationCreditAvailable: true,
          })
          dispatch(getProfile(user._id))
          dispatch(setDisClaimerModal(true))
          Sentry.captureMessage(
            `Payment successfull for Profile - ${franchisee}`
          )
        },
      }
      //@ts-ignore
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
      paymentObject.on('payment.failed', function (response) {
        console.error({
          message: 'Payment Failed',
          description: `Payment Failed. Reason : ${response.error.reason}`,
        })
      })
    }
  } catch (e) {
    dispatch(setDisClaimerModal(false))
    notifier.error(
      'Unable to proceed for payment. Please contact the system admin !'
    )
    return
  }
}




export const generateFollowupCall = (pres: any) => async (dispatch) => {
  try {
    const response = await axios.post('/smartclinic/followup', {
      prescriptionId: pres._id,
    })
    const newPrescription = response.data.response.data
    window.location.pathname = `/consultation/${newPrescription._id}`
  } catch (err) {
    console.error(err)
  }
}

export const addVitalTest = (productId) => async (dispatch) => {
  try {
    const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.patch(`/prescription/addon/add`, {
      prescriptionId: prescription._id,
      productId,
    })
    const data = response.data.response.data
    dispatch(updateSelectedPrescription(data))
    notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    dispatch(setError(error))
  }
}

// exporting the reducer here, as we need to add this to the store
export default PrescriptionSlice.reducer
