import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/home";
import { toggleOrderModal } from "../../store/slices/modalSlice";
import { Button } from "../buttons/button";
import SvgIcon from "../shared/SvgIcon";
import { useRouter } from "next/router";

const OrderModal = () => {
    const router = useRouter()
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.modal.orderModal.show)

    const toggle = () => {
        dispatch(toggleOrderModal())
    }

    const redirect = (type) => {
        toggle()
        router.push(`/orders?type=${type}`)
    }


    return (
        <Transition appear show={show} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={toggle}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full single-article max-w-2xl transform overflow-hidden rounded-2xl  text-[#1B1E1F] bg-white p-6 text-center align-middle shadow-xl transition-all">
                                <p className="font-semibold mb-10">Track Details</p>

                                <div className='grid grid-cols-2 gap-5'>
                                    <div className="flex flex-col items-center p-2 rounded-lg border justify-between">
                                        <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Orders Made By Attendee </p>
                                        <Button active outline green onClick={() => redirect('attendee')} children="Track now" className='p-3 px-5 w-fit mt-6' />
                                    </div>
                                    {/* <div className="flex flex-col items-center p-2 rounded-lg border justify-between">
                                        <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Track Diagnostics</p>
                                        <Button active outline green onClick={() => redirect('diagnostics')} children="Track now" className='p-3 px-5 w-fit mt-6' />
                                    </div> */}
                                    <div className="flex flex-col items-center p-2 rounded-lg border justify-between">
                                        <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Product Sale</p>
                                        <Button active outline green onClick={() => redirect('products')} children="Track now" className='p-3 px-5 w-fit mt-6' />
                                    </div>


                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default OrderModal;