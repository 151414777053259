import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { store } from '../../store'

// declaring the types for our state
export type MedDiagnosticState = {
  loading: boolean
  error: any | null
  testData: any[]
  habbitData: any | null
  listData: any[]
  selectedData: any | {}
  cartData: any | {}
  patientData: any[]
  memberData: any | {}
  bookingData: {
    userDetail: any[]
    locationDetail: any | {}
  }
  selectedMemberData: any | []
}

const initialState: MedDiagnosticState = {
  error: null,
  loading: true,
  testData: [],
  habbitData: [],
  listData: [],
  selectedData: {},
  cartData: {},
  patientData: [],
  memberData: {},
  bookingData: {
    userDetail: [],
    locationDetail: {},
  },
  selectedMemberData: [],
}

export const medDiagnosticSlice = createSlice({
  name: 'meddiagnostic',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTestData: (state, action: PayloadAction<any>) => {
      state.testData = action.payload
    },
    setHabbitData: (state, action: PayloadAction<any>) => {
      state.habbitData = action.payload
    },
    setListData: (state, action: PayloadAction<any>) => {
      state.listData = action.payload
    },
    setPatientData: (state, action: PayloadAction<any>) => {
      state.patientData = action.payload
    },
    setCartData: (state, action: PayloadAction<any>) => {
      state.cartData = action.payload
    },
    setMemberData: (state, action: PayloadAction<any>) => {
      state.memberData = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setSelectedData: (state, action: PayloadAction<any>) => {
      state.selectedData = action.payload
    },
    setSaveUserData: (state, action: PayloadAction<any>) => {
      state.bookingData.userDetail = action.payload
    },
    setSaveLocationData: (state, action: PayloadAction<any>) => {
      state.bookingData.locationDetail = action.payload
    },
    setSelectedMemberData: (state, action: PayloadAction<any>) => {
      state.selectedMemberData = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setLoading,
  setTestData,
  setHabbitData,
  setListData,
  setSelectedData,
  setCartData,
  setPatientData,
  setMemberData,
  setSaveUserData,
  setSaveLocationData,
  setSelectedMemberData,
  setError,
} = medDiagnosticSlice.actions

//API Calls
export const getDiagnosticData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.get(`/test/home`)
      const data = response.data.response
      dispatch(setTestData(data.testCategory))
      dispatch(setHabbitData(data.habbits))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const getList =
  (productType: any, city: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.patch(`/testsByFilter`, {
        category: productType,
        city,
      })
      const data = response.data.response.data
      dispatch(setListData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const getData =
  (clinicId: any, id: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.get(`/tests/${clinicId}/${id}`)
      const data = response.data.response.data
      dispatch(setSelectedData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const getPatientData =
  (phone: number) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.post(`/users-appointment/verifyPhone`, {
        phone: phone,
      })
      const data = response.data.response.data
      dispatch(setPatientData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const updateUserDetails = (values: any) => async (dispatch) => {
  try {
    const res = await axios.post('/users/ByNurse', values)
    const data = res.data.response.data
    let response = await axios.post(`/users-appointment/verifyPhone`, {
      phone: values.phone || values.memberPhone,
    })
    const data2 = response.data.response.data
    dispatch(setPatientData(data2))
  } catch (err) {
    console.error(err)
  }
}

export const saveMemberData =
  (values: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const res = await axios.post('/addMember', values)
      const data = res.data.response.data
      dispatch(setMemberData(data))
      let response = await axios.post(`/users-appointment/verifyPhone`, {
        phone: values.phone || values.memberPhone,
      })
      const data2 = response.data.response.data
      dispatch(setPatientData(data2))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const getCartDiagnosticData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.get(`/franchisee/test/cart`)
      const data = response.data.response.data
      dispatch(setCartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const addTestToCart =
  (values: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const res = await axios.post('/franchisee/test/cart', values)
      let data = res.data.response.data
      dispatch(setCartData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const updateCartData =
  (cartId: any, data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch(
        `/franchisee/update/test/${cartId}`,
        data
      )
      const res = response.data.response.data
      dispatch(setCartData(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const deleteCartData =
  (cartId: any, data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch(
        `/franchisee/update/test/${cartId}`,
        data
      )
      const res = response.data.response.data
      dispatch(setCartData(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const submitOrder =
  (data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.post(`/bookAppointment`, data)
      const res = response.data.response.data
      // dispatch(setCartData(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const setLocationData =
  (cartId: any, data: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const res = await axios.patch(`/franchisee/update/test/${cartId}`, data)
      dispatch(setSaveLocationData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const saveFinalData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      let user = store.getState().medDiagnostic.patientData
      //let locationData = store.getState().medDiagnostic.locationData
      dispatch(setSaveUserData(user))
      //dispatch(setSaveLocationData(locationData))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const clearCartData =
  (cartId: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      console.log({ cartId })
      dispatch(setLoading(true))
      const response = await axios.patch(`/removeFromCart/${cartId}`, {
        clearCart: true,
      })
      const res = response.data.response.data
      dispatch(setCartData(res))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default medDiagnosticSlice.reducer
